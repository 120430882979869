<template>
  <form-wizard color="#0d40b2"
               :title="null"
               :subtitle="null"
               shape="square"
               finish-button-text="提交"
               back-button-text="上一步"
               next-button-text="下一步"
               class="mb-3"
               @on-complete="complete">
    <tab-content title="基础信息"
                 :before-change="validationStepOne">
      <CookieSaveStepOne :form="form"
                         ref="cookieSaveStepOne" />
    </tab-content>
    <tab-content title="初始设置"
                 :before-change="validationStepTwo">
      <CookieSaveStepTwo :form="form"
                         ref="cookieSaveStepTwo" />
    </tab-content>
    <tab-content title="详细设置"
                 :before-change="validationStepThree">
      <CookieSaveStepThree :form="form"
                           ref="cookieSaveStepThree" />
    </tab-content>
    <tab-content title="悬浮球入口"
                 :before-change="validationStepFour">
      <CookieSaveStepFour :form="form"
                          ref="cookieSaveStepFour" />
    </tab-content>

  </form-wizard>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import CookieSaveStepOne from '@/views/cookies/CookieTemplateSave/CookieSaveStepOne'
import CookieSaveStepTwo from '@/views/cookies/CookieTemplateSave/CookieSaveStepTwo'
import CookieSaveStepThree from '@/views/cookies/CookieTemplateSave/CookieSaveStepThree'
import CookieSaveStepFour from '@/views/cookies/CookieTemplateSave/CookieSaveStepFour'
import { saveOrUpdateCookieTheme, GetThemeById } from '@/api/cpm/cpm'
import { error, success } from '@core/utils/utils'
export default {
  name: 'CookieTemplateSave',
  components: {
    FormWizard,
    TabContent,
    CookieSaveStepOne,
    CookieSaveStepTwo,
    CookieSaveStepThree,
    CookieSaveStepFour,
  },
  created () {
    if (this.$route.query.id) {
      this.form.id = Number(this.$route.query.id)
      this.getThemeById()
    }
  },
  mounted () {
    this.navActiveInit(1, '模板管理', 'Cookie管理')
  },
  data () {
    return {
      form: {
        cookieIdList: [],
        title: 'Cookie 同意主题', // 主题名称
        language: '简体中文', // 主题语言
        describe: '', // 主题描述
        themeColor: '#409EFF', // 主题色
        LawId: '', // 适用法律
        // 第二步参数
        initialSetting: {
          bannerTitle: 'banner标题', // banner标题
          bannerDesc: 'banner描述', // banner描述
          agreeAllBtnText: '同意所有', // 同意所有
          agreeAllBtnTextColor: '#ffffff', // 同意所有
          agreeAllBtnBgColor: '#409EFF', // 同意所有
          bannerTextColor: '#ffffff', // banner字体颜色
          bannerBgColor: '#409EFF', // banner背景色
          requireRefuse: true, // 拒绝按钮
          refuseBtnText: '拒绝所有', // 拒绝按钮文字
          refuseBtnTextColor: '#ffffff', // 拒绝按钮文字颜色
          refuseBtnBgColor: '#409EFF', // 拒绝按钮背景颜色
          detailBgColor: '#409EFF', // 详细设置背景色
          detailBtnText: '详细设置', // 详细设置文字
          detailTextColor: '#ffffff', // 详细设置文字颜色
        },
        // 第三步参数
        detailsSetting: {
          detailTitle: '标题', // 详情标题
          detailDesc: '标题', // 详情描述
          privacyTextColor: '#000000', // 隐私政策文字颜色
          setText: 'Cookie设置', // 设置说明文字
          openColor: '#409EFF', // 开关颜色
          saveBtnText: '保存设置', // 保存设置文字
          saveBtnTextColor: '#ffffff', // 保存设置文字颜色
          saveBtnBgColor: '#409EFF', // 保存设置背景色
          requireDetailAgreeAllBtn: true, // 详情同意所有按钮
          detailAgreeAllBtnText: '同意所有', // 详情同意所有按钮文字
          detailAgreeAllBtnColor: '#ffffff', // 详情同意所有按钮文字颜色
          detailAgreeAllBtnBgColor: '#409EFF', // 详情同意所有按钮文字背景颜色
          requireDetailRefuse: true, // 详情拒绝所有按钮
          detailRefuseText: '拒绝所有', // 详情拒绝所有按钮
          detailRefuseColor: '#ffffff', // 详情拒绝所有按钮
          detailRefuseBgColor: '#409EFF', // 详情拒绝所有按钮
          showBottonText: true, // 是否显示技术支持
        },
        // 第四步参数
        floatingBallSetting: {
          floatPosition: '1',
          floatBtnPositionX: '20',
          floatBtnPositionY: '20',
          floatText: 'Cookie设置',
        },
        floatingBallPicture: '',
        logoPicture: '',

      },
      isCopy: this.$route.query.c
    }
  },
  methods: {
    getThemeById () {
      GetThemeById(this.form.id).then(res => {
        const resData = res.data.data
        if (res.data.code === 0) {
          resData.CookieInfoList.forEach(c => {
            this.form.cookieIdList.push(c.id)
          })
          this.form.title = resData.title
          this.form.language = resData.language
          this.form.describe = resData.describe
          this.form.themeColor = resData.themeColor
          this.form.LawId = resData.LawId
          this.form.logoPicture = resData.logoPicture
          this.form.floatingBallPicture = resData.floatingBallPicture
          this.form.initialSetting = JSON.parse(resData.initialSetting)
          this.form.detailsSetting = JSON.parse(resData.detailsSetting)
          this.form.floatingBallSetting = JSON.parse(resData.floatingBallSetting)
        }

      })
    },
    complete () {
      const isCopy = this.$route.query.c
      const submitData = {}
      const excludeField = ['initialSetting', 'detailsSetting', 'floatingBallSetting']
      for (var i in this.form) {
        if (excludeField.indexOf(i) === -1) {
          submitData[i] = this.form[i]
        }
      }
      submitData.initialSetting = JSON.stringify(this.form.initialSetting)
      submitData.detailsSetting = JSON.stringify(this.form.detailsSetting)
      submitData.floatingBallSetting = JSON.stringify(this.form.floatingBallSetting)
      if (this.isCopy) {
        delete submitData.id
      }
      saveOrUpdateCookieTheme(submitData).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          success(resData.msg)
          this.$router.push({ path: '/cookies-template' })
        } else {
          error(resData.msg)
        }
      })
    },
    validationStepOne () {
      return this.$refs.cookieSaveStepOne.validate()
    },
    validationStepTwo () {
      if (this.form.logoPicture) {
        this.$refs.cookieSaveStepThree.logoPicture = this.form.logoPicture
      }
      return this.$refs.cookieSaveStepTwo.validate()
    },
    validationStepThree () {
      if (this.form.floatingBallPicture) {
        this.$refs.cookieSaveStepFour.floatingBallPicture = this.form.floatingBallPicture
      }
      return this.$refs.cookieSaveStepThree.validate()
    },
    validationStepFour () {
      return this.$refs.cookieSaveStepFour.validate()
    }
  },
  destroyed () {
    this.navActiveInit(2, '模板管理', 'Cookie管理', '/cookies-template')
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
