<template>
  <div class="cookie-content">
    <el-form ref="form"
             style="height: 500px"
             :model="form"
             label-width="110px"
             :rules="rules">
      <el-form-item label="Banner标题"
                    prop="initialSetting.bannerTitle">
        <el-input  v-input-filter v-model="form.initialSetting.bannerTitle"
                  maxlength="32" />
      </el-form-item>
      <el-form-item label="简述"
                    prop="initialSetting.bannerDesc">
        <el-input  v-input-filter type="textarea"
                  v-model="form.initialSetting.bannerDesc" />
        <!--                        支持插入链接，格式为：'《a href=https://convertlab.com target=_blank>隐私政策《/a>'-->
      </el-form-item>

      <div class="flex-start"
           style="align-items: baseline">
        <el-form-item label="同意所有按钮"
                      prop="initialSetting.agreeAllBtnText">
          <el-input  v-input-filter v-model="form.initialSetting.agreeAllBtnText"
                    style="width: 80px"
                    maxlength="32" />
        </el-form-item>
        <el-form-item label="字体色"
                      prop="initialSetting.agreeAllBtnTextColor">
          <el-color-picker v-model="form.initialSetting.agreeAllBtnTextColor"></el-color-picker>
        </el-form-item>
        <el-form-item label="背景色"
                      prop="initialSetting.agreeAllBtnBgColor">
          <el-color-picker v-model="form.initialSetting.agreeAllBtnBgColor"></el-color-picker>
        </el-form-item>
      </div>
      <div class="flex-start"
           style="align-items: baseline">
        <el-form-item label="详细设置按钮"
                      prop="initialSetting.detailBtnText">
          <el-input  v-input-filter v-model="form.initialSetting.detailBtnText"
                    maxlength="32" />
        </el-form-item>
        <el-form-item label="字体色"
                      prop="initialSetting.detailTextColor">
          <el-color-picker v-model="form.initialSetting.detailTextColor"></el-color-picker>
        </el-form-item>
        <el-form-item label="背景色"
                      prop="initialSetting.detailBgColor">
          <el-color-picker v-model="form.initialSetting.detailBgColor"></el-color-picker>
        </el-form-item>
      </div>
      <el-form-item label="拒绝按钮">
        <el-switch v-model="form.initialSetting.requireRefuse"></el-switch>
      </el-form-item>
      <div class="flex-start"
           v-show="form.initialSetting.requireRefuse"
           style="align-items: baseline">
        <el-form-item label="拒绝按钮"
                      prop="initialSetting.refuseBtnText">
          <el-input  v-input-filter v-model="form.initialSetting.refuseBtnText"
                    maxlength="32" />
        </el-form-item>
        <el-form-item label="字体色"
                      prop="initialSetting.refuseBtnTextColor">
          <el-color-picker v-model="form.initialSetting.refuseBtnTextColor"></el-color-picker>
        </el-form-item>
        <el-form-item label="背景色"
                      prop="initialSetting.refuseBtnBgColor">
          <el-color-picker v-model="form.initialSetting.refuseBtnBgColor"></el-color-picker>
        </el-form-item>
      </div>
      <el-form-item label="banner 背景色"
                    prop="initialSetting.bannerBgColor">
        <el-color-picker v-model="form.initialSetting.bannerBgColor"></el-color-picker>
      </el-form-item>
      <el-form-item label="banner 字体色"
                    prop="initialSetting.bannerTextColor">
        <el-color-picker v-model="form.initialSetting.bannerTextColor"></el-color-picker>
      </el-form-item>
    </el-form>
    <div class="browser-content">
      <div class="brower-header">
        <span>-</span>
        <span>□</span>
        <span>x</span>
      </div>
      <div class="brower-box1"></div>
      <div class="brower-box2">
        <div class="brower-box1"></div>
        <div class="brower-box1"></div>
        <div class="brower-box1"></div>
      </div>
      <div class="banner-fixed"
           :style="{background:form.initialSetting.bannerBgColor}">
        <div class="banner-left">
          <div :style="{color:form.initialSetting.bannerTextColor}">{{form.initialSetting.bannerTitle}}</div>
          <div class="banner-desc"
               :style="{color:form.initialSetting.bannerTextColor}">{{form.initialSetting.bannerDesc}}</div>
        </div>
        <div class="banner-right">
          <div :style="{color:form.initialSetting.detailTextColor,background: form.initialSetting.detailBgColor}">{{form.initialSetting.detailBtnText}}</div>
          <div v-show="form.initialSetting.requireRefuse"
               :style="{color:form.initialSetting.refuseBtnTextColor,background: form.initialSetting.refuseBtnBgColor}">{{form.initialSetting.refuseBtnText}}</div>
          <div :style="{color:form.initialSetting.agreeAllBtnTextColor,background: form.initialSetting.agreeAllBtnBgColor}">{{form.initialSetting.agreeAllBtnText}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CookieSaveStepOne',
  props: {
    form: {
      type: Object
    }
  },
  data () {
    return {
      rules: {
        'initialSetting.bannerTitle': [
          { required: true, message: 'Banner标题不能为空', trigger: 'change' }
        ],
        'initialSetting.bannerDesc': [
          { required: true, message: '不能为空', trigger: 'change' }
        ],
        'initialSetting.agreeAllBtnText': [
          { required: true, message: '不能为空', trigger: 'change' }
        ],
        'initialSetting.agreeAllBtnTextColor': [
          { required: true, message: '不能为空', trigger: 'change' }
        ],
        'initialSetting.agreeAllBtnBgColor': [
          { required: true, message: '不能为空', trigger: 'change' }
        ],
        'initialSetting.detailBtnText': [
          { required: true, message: '不能为空', trigger: 'change' }
        ],
        'initialSetting.detailTextColor': [
          { required: true, message: '不能为空', trigger: 'change' }
        ],
        'initialSetting.detailBgColor': [
          { required: true, message: '不能为空', trigger: 'change' }
        ],
        'initialSetting.refuseBtnText': [
          { required: true, message: '不能为空', trigger: 'change' }
        ],
        'initialSetting.refuseBtnTextColor': [
          { required: true, message: '不能为空', trigger: 'change' }
        ],
        'initialSetting.refuseBtnBgColor': [
          { required: true, message: '不能为空', trigger: 'change' }
        ],
        'initialSetting.bannerBgColor': [
          { required: true, message: '不能为空', trigger: 'change' }
        ],
        'initialSetting.bannerTextColor': [
          { required: true, message: '不能为空', trigger: 'change' }
        ],
      }
    }
  },
  methods: {
    validate () {
      var flag = false
      this.$refs['form'].validate((valid) => {
        if (valid) {
          flag = true
        } else {
          flag = false
        }
      })
      return flag
    }
  }
}
</script>

<style >
.cookie-content {
  width: 100%;
  height: 100%;
  display: flex;
}
.brower-header {
  width: 100%;
  height: 20px;
  text-align: right;
  line-height: 20px;
  border-bottom: 1px solid #d7d7d7;
  border-radius: 5px 5px 0 0;
  background-color: #e0e0e0;
}
.brower-header span {
  padding-right: 10px;
}
.brower-box2 {
  margin: 20px;
  display: flex;
}
.brower-box2 .brower-box1 {
  width: 30%;
}
.brower-box1 {
  height: 100px;
  margin: 20px;
  border-radius: 5px;
  background-color: #e0e0e0;
}
.browser-content {
  position: relative;
  width: 100%;
  height: auto;
  margin-left: 50px;
  border: 1px solid #d7d7d7;
  background-color: #f4f4f4;
  border-radius: 5px;
}
.banner-right {
  display: flex;
  justify-content: flex-end;
}
.banner-right div {
  margin: 0 4px;
  padding: 5px 10px;
}
.banner-left {
  max-width: 60%;
}
.banner-desc {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  width: 100%;
}
.banner-fixed {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  color: rgb(255, 255, 255);
  padding: 8px 20px;
  height: 60px;
  font-size: 12px;
  border-radius: 4px;
  transform-origin: left bottom;
  width: 100%;
}
</style>
