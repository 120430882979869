<template>
  <div class="cookie-content">
    <el-form ref="form"
             style="width: 600px"
             :model="form"
             :rules="rules"
             label-width="140px">
      <el-form-item label="按钮图片"
                    prop="floatingBallPicture"
                    :rules="[ { required: true, message: '请上传图片', trigger: 'change' }]">
        <img width="120"
             @click="logoFileClick"
             :src="floatingBallPicture"
             v-if="floatingBallPicture" />
        <!--                        <el-image @click="logoFileClick" v-if="floatingBallPicture" :src="floatingBallPicture"></el-image>-->
        <el-button size="small"
                   v-if="!floatingBallPicture"
                   @click="logoFileClick">上传图片</el-button>
        <input accept="image/*"
               type="file"
               id="logoFile2"
               @change="fileSelect"
               style="display: none">
        <div>推荐图片宽高比1:1，大小在200k以内</div>
      </el-form-item>
      <el-form-item label="距离下底部">
        <el-radio v-model="form.floatingBallSetting.floatPosition"
                  label="1">位于页面左下角</el-radio>
        <el-radio v-model="form.floatingBallSetting.floatPosition"
                  label="2">位于页面右下角</el-radio>
      </el-form-item>
      <el-form-item label="距离下底部"
                    prop="floatingBallSetting.floatBtnPositionY">
        <el-input type="number"
                  v-input-filter
                  v-model="form.floatingBallSetting.floatBtnPositionY"
                  placeholder="请输入值,列如20" />
      </el-form-item>
      <el-form-item :label="form.floatingBallSetting.floatBtnPositionX === '1' ? '距离左侧边' : '距离右侧边'"
                    prop="floatingBallSetting.floatBtnPositionX">
        <el-input type="number"
                  v-input-filter
                  v-model="form.floatingBallSetting.floatBtnPositionX"
                  placeholder="请输入值,列如20" />
      </el-form-item>
      <el-form-item label="鼠标悬浮文字显示"
                    prop="floatingBallSetting.floatText">
        <el-input  v-input-filter v-model="form.floatingBallSetting.floatText"
                  placeholder="请输入悬浮文字" />
      </el-form-item>
    </el-form>
    <div class="browser-content">
      <div class="brower-header">
        <span>-</span>
        <span>□</span>
        <span>x</span>
      </div>
      <div class="brower-box1"></div>
      <div class="brower-box2">
        <div class="brower-box1"></div>
        <div class="brower-box1"></div>
        <div class="brower-box1"></div>
      </div>
      <div class="brower-box3"
           :style="form.floatingBallSetting.floatPosition == '1' ? {left: form.floatingBallSetting.floatBtnPositionX + 'px', bottom: form.floatingBallSetting.floatBtnPositionY + 'px'} : {right: form.floatingBallSetting.floatBtnPositionX + 'px', bottom: form.floatingBallSetting.floatBtnPositionY + 'px'}">
        <img width="40"
             :src="floatingBallPicture">
        <span class="cookie-text">{{ form.floatingBallSetting.floatText }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { UploadFile } from '@/api/system/system'
import { error, success } from '@core/utils/utils'


export default {
  name: 'CookieSaveStepOne',
  props: {
    form: {
      type: Object
    }
  },
  data () {
    return {
      floatingBallPicture: '',
      rules: {
        'floatingBallSetting.floatBtnPositionX': [
          { required: true, message: 'Banner标题不能为空', trigger: 'change' }
        ],
        'floatingBallSetting.floatBtnPositionY': [
          { required: true, message: '不能为空', trigger: 'change' }
        ],
        'floatingBallSetting.floatText': [
          { required: true, message: '不能为空', trigger: 'change' }
        ],
      }
    }
  },
  methods: {
    validate () {
      var flag = false
      this.$refs['form'].validate((valid) => {
        if (valid) {
          flag = true
        } else {
          flag = false
        }
      })
      return flag
    },
    logoFileClick () {
      document.getElementById('logoFile2').click()
    },
    fileSelect () {
      const uploadDom = document.getElementById('logoFile2')
      const formData = new FormData()
      formData.append('file', uploadDom.files[0])
      UploadFile(formData).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          success(resData.msg)
          this.floatingBallPicture = resData.data.assessPath
          this.form.floatingBallPicture = resData.data.filePath
        } else {
          error(resData.msg)
        }
      })
    }
  }
}
</script>

<style >
.brower-box3 {
  position: absolute;
  transition: all 300ms ease;
  border-radius: 8px;
  cursor: pointer;
}
.cookie-text {
  display: none;
  padding: 5px;
  font-weight: bold;
}
.brower-box3:hover {
  padding: 8px;
  background-color: #fff;
}
.brower-box3:hover .cookie-text {
  display: inline;
}
.el-collapse-item__header {
  padding: 0 10px;
}
.el-collapse-item__content {
  padding: 0 10px 25px 10px;
}
</style>
