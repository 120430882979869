import { downloadByGet, get, post } from '@/libs/axios'

// eslint-disable-next-line import/prefer-default-export
// 法律合规列表
export const listData = params => post('/pia/assess/GetComplianceAssessListByPage', params)

// 获取所有合规类型与法律
export const getAllLawList = () => post('/compliance/law/GetAllLawList')
export const GetCountByLawType = params => post('/compliance/assess/GetCountByLawType', params)
// 删除合规评估
export const DeleteAssessByIds = params => post('/compliance/assess/DeleteAssessByIds', params)
// 法律合规详情
export const findAssessDetail = params => post('/compliance/assess/FindAssessDetail', params)
// 获取答题选项
export const getAssessDetailAllQuestion = params => post('/compliance/assess/GetAssessDetailAllQuestion', params)
// 提交答案
export const commitAssessAnswer = params => post('/compliance/assess/CommitAssessAnswer', params)
// 统计
export const getCountByLawType = params => post('/compliance/assess/GetCountByLawType', params)
// 查看报告
export const getAssessReport = params => post('/compliance/assess/GetAssessReport', params)
export const GetGapAnalysisTreeList = params => post('/compliance/gapAnalysis/GetGapAnalysisTreeList', params)
export const GetGapAnalysisTypeList = id => get(`/compliance/gapAnalysis/GetGapAnalysisTypeList?id=${id}`)
export const GetComplianceSatisfaction = () => get('/pia/assess/GetComplianceAssessSatisfaction')
export const GetGapAnalysisSatisfaction = params => post('/compliance/gapAnalysis/GetGapAnalysisSatisfaction', params)
export const GetQuestionListByTypeId = id => post('/compliance/gapAnalysis/GetQuestionListByTypeId', id)
export const AddOrUpdateGapAnalysis = params => post('/compliance/gapAnalysis/AddOrUpdateGapAnalysis', params)
export const GetGapAnalysisByQuestionId = params => post('/compliance/gapAnalysis/GetGapAnalysisByQuestionId', params)
export const GetGapAssessListByPag = params => post('/compliance/gapAssess/GetGapAssessListByPage', params)
export const DeleteGapAssessByIds = params => post('/compliance/gapAssess/DeleteGapAssessByIds', params)
export const FinishGapAssess = params => post('/compliance/gapAssess/FinishGapAssess', params)
export const DownloadAssessReportPDF = id => downloadByGet('/compliance/assess/DownloadAssessReportPDF', { id })
export const GetGapAssessReport = id => downloadByGet('/compliance/gapAssess/GetGapAssessReport', { id })
export const DownloadReportPDF = id => downloadByGet('/compliance/gapAssess/DownloadReportPDF', { id })

export const saveOrUpdateGapAssess = params => {
  const submitUrl = params.id ? '/compliance/gapAssess/UpdateGapAssess' : '/compliance/gapAssess/CreateGapAssess'
  return post(submitUrl, params)
}
export const saveOrUpdateAssess = params => {
  const submitUrl = params.id ? '/compliance/assess/UpdateAssess' : '/compliance/assess/CreateAssess'
  return post(submitUrl, params)
}
