<template>
  <div class="cookie-content">
    <el-form
      ref="form"
      :model="form"
      label-width="130px"
      :rules="rules"
    >
      <el-form-item
        label="logo"
        prop="logoPicture"
        :rules="[ { required: true, message: '请上传图片', trigger: 'change' }]"
      >
        <img
          v-show="logoPicture"
          width="50"
          :src="logoPicture"
          style="cursor: pointer"
          @click="logoFileClick"
        >
        <!--                       <img src="../../../assets/images/logo/logo2.svg" style="cursor: pointer" @click="logoFileClick">-->
        <!--                        <el-image @click="logoFileClick" v-if="logoPicture" :src="logoPicture"></el-image>-->
        <el-button
          v-show="!logoPicture"
          size="small"
          @click="logoFileClick"
        >上传图片</el-button>
        <input
          id="logoFile"
          accept="image/*"
          type="file"
          style="display: none"
          @change="fileSelect"
        >
      </el-form-item>
      <el-form-item
        label="标题"
        prop="detailsSetting.detailTitle"
      >
        <el-input
          v-model="form.detailsSetting.detailTitle"
          v-input-filter
          maxlength="32"
        />
      </el-form-item>
      <el-form-item
        label="描述"
        prop="detailsSetting.detailDesc"
      >
        <el-input
          v-model="form.detailsSetting.detailDesc"
          v-input-filter
          type="textarea"
          placeholder="请输入描述"
        />
      </el-form-item>
      <el-form-item
        label="Cookie设置"
        prop="cookieIdList"
        :rules="[ { required: true, message: 'cookie不能为空', trigger: 'change' }]"
      >
        <el-select
          v-model="form.cookieIdList"
          multiple
        >
          <el-option
            v-for="(item,index) in cookieOption"
            :key="index"
            :value="item.id"
            :label="item.cookieName"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        label="设置说明文字"
        prop="detailsSetting.setText"
      >
        <el-input
          v-model="form.detailsSetting.setText"
          v-input-filter
          maxlength="32"
        />
      </el-form-item>
      <el-form-item
        label="开关开启颜色"
        prop="openColor"
      >
        <el-color-picker
          v-model="form.detailsSetting.openColor"
          @active-change="colorChange"
        />
      </el-form-item>

      <div
        class="flex-start"
        style="align-items: baseline"
      >
        <el-form-item
          label="保存设置按钮"
          prop="detailsSetting.saveBtnText"
        >
          <el-input
            v-model="form.detailsSetting.saveBtnText"
            style="width: 80px"
            maxlength="32"
          />
        </el-form-item>
        <el-form-item
          label="字体色"
          prop="detailsSetting.saveBtnTextColor"
        >
          <el-color-picker v-model="form.detailsSetting.saveBtnTextColor" />
        </el-form-item>
        <el-form-item
          label="背景色"
          prop="detailsSetting.saveBtnBgColor"
        >
          <el-color-picker v-model="form.detailsSetting.saveBtnBgColor" />
        </el-form-item>
      </div>
      <el-form-item label="拒绝所有按钮">
        <el-switch v-model="form.detailsSetting.requireDetailRefuse" />
      </el-form-item>
      <div
        v-if="form.detailsSetting.requireDetailRefuse"
        class="flex-start"
        style="align-items: baseline"
      >
        <el-form-item
          label="拒绝所有按钮"
          prop="detailsSetting.detailRefuseText"
        >
          <el-input
            v-model="form.detailsSetting.detailRefuseText"
            v-input-filter
            style="width: 80px"
            maxlength="32"
          />
        </el-form-item>
        <el-form-item
          label="字体色"
          prop="detailsSetting.requireDetailRefuseColor"
        >
          <el-color-picker v-model="form.detailsSetting.detailRefuseColor" />
        </el-form-item>
        <el-form-item
          label="背景色"
          prop="detailsSetting.requireDetailRefuseBgColor"
        >
          <el-color-picker v-model="form.detailsSetting.detailRefuseBgColor" />
        </el-form-item>
      </div>
      <el-form-item label="同意所有按钮">
        <el-switch v-model="form.detailsSetting.requireDetailAgreeAllBtn" />
      </el-form-item>
      <div
        v-if="form.detailsSetting.requireDetailAgreeAllBtn"
        class="flex-start"
        style="align-items: baseline"
      >
        <el-form-item
          label="同意所有按钮"
          prop="detailsSetting.detailAgreeAllBtnText"
        >
          <el-input
            v-model="form.detailsSetting.detailAgreeAllBtnText"
            v-input-filter
            style="width: 80px"
            maxlength="32"
          />
        </el-form-item>
        <el-form-item
          label="字体色"
          prop="detailsSetting.detailAgreeAllBtnColor"
        >
          <el-color-picker v-model="form.detailsSetting.detailAgreeAllBtnColor" />
        </el-form-item>
        <el-form-item
          label="背景色"
          prop="detailsSetting.detailAgreeAllBtnBgColor"
        >
          <el-color-picker v-model="form.detailsSetting.detailAgreeAllBtnBgColor" />
        </el-form-item>
      </div>
      <el-form-item
        label="隐私政策字体色"
        prop="detailsSetting.privacyTextColor"
      >
        <el-color-picker v-model="form.detailsSetting.privacyTextColor" />
      </el-form-item>
      <el-form-item
        label="底部说明文字"
        prop="detailsSetting.showBottonText"
      >
        <el-checkbox v-model="form.detailsSetting.showBottonText">
          显示PICaaS技术支持
        </el-checkbox>
      </el-form-item>
    </el-form>
    <div class="browser-content">
      <div class="brower-header">
        <span>-</span>
        <span>□</span>
        <span>x</span>
      </div>
      <div class="brower-box1" />
      <div class="brower-box2">
        <div class="brower-box1" />
        <div class="brower-box1" />
        <div class="brower-box1" />
      </div>
      <div class="preview-wrap">
        <div style="padding: 10px;">
          <img
            v-if="logoPicture"
            width="30"
            :src="logoPicture"
          >
          <i
            class="el-icon el-icon-close"
            style="float: right"
          />
        </div>
        <div class="title-wrap preview-item">
          <div class="title">
            {{ form.detailsSetting.detailTitle }}
          </div>
          <div style="text-indent: 2em;">
            {{ form.detailsSetting.detailDesc }}
          </div>
        </div>
        <div class="preview-item">
          <div class="title">
            {{ form.detailsSetting.setText }}
          </div>
          <el-collapse
            v-model="activeName"
            accordion
          >
            <el-collapse-item
              v-for="(item,index) in cookieList"
              :key="index"
              :name="index"
            >
              <template slot="title">
                {{ item.title }}
                <div
                  role="switch"
                  aria-checked="true"
                  class="el-switch is-checked"
                  style="margin-left: 10px;"
                >
                  <input
                    type="checkbox"
                    name=""
                    true-value="true"
                    class="el-switch__input"
                  >
                  <span
                    class="el-switch__core"
                    :style="{width: '40px',borderColor:form.detailsSetting.openColor,backgroundColor: form.detailsSetting.openColor}"
                  />
                </div>

              </template>
              <div>{{ item.desc }}</div>
            </el-collapse-item>
          </el-collapse>
        </div>
        <div class="cookie-btns">
          <div class="flex-between mb20 preview-btn-wrap">
            <div
              v-if="form.detailsSetting.requireDetailRefuse"
              class="preview-btn mr10"
              :style="{color:form.detailsSetting.detailRefuseColor,background: form.detailsSetting.detailRefuseBgColor}"
            >{{ form.detailsSetting.detailRefuseText }}</div>
            <div
              class="preview-btn"
              :style="{color:form.detailsSetting.saveBtnTextColor,background: form.detailsSetting.saveBtnBgColor,width: form.detailsSetting.requireDetailRefuse ? '50%' : '100%'}"
            >{{ form.detailsSetting.saveBtnText }}</div>
          </div>
          <div
            v-if="form.detailsSetting.requireDetailAgreeAllBtn"
            class="preview-btn mb20"
            :style="{color:form.detailsSetting.detailAgreeAllBtnColor,background: form.detailsSetting.detailAgreeAllBtnBgColor}"
          >{{ form.detailsSetting.detailAgreeAllBtnText }}</div>
          <div style="text-align: center">
            <span :style="{color: form.detailsSetting.privacyTextColor}">隐私政策</span> <span v-show="form.detailsSetting.showBottonText">PICaaS技术支持</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GetCookieOption } from '@/api/cpm/cpm'
import { UploadFile } from '@/api/system/system'
import { error, success } from '@core/utils/utils'

export default {
  name: 'CookieSaveStepOne',
  props: {
    form: {
      type: Object,
    },
  },
  data() {
    return {
      switchVal: true,
      activeName: '1',
      logoPicture: '',
      cookieList: [
        { title: '必要', desc: '这些 cookie 设置为提供所请求的服务、应用程序或资源。如果没有这些 cookie，您的请求将无法正确传递。它们通常设置为管理您所做的操作，例如请求网站视觉元素、页面资源或适当的用户登录/注销。我们还可以使用这些 cookie 设置基本功能，以保证所请求服务的安全性和效率，例如身份验证和负载均衡器请求。' },
        { title: '功能', desc: '这些 cookie 由我们或我们用来实现附加功能或增强功能和网站性能的第三方服务提供商设置，但它们与您请求的服务没有直接关系。这些 cookie 实现的服务和功能支持自动填充文本框、实时网络聊天平台、非必要表单和可选安全参数（如单点登录 (SSO)）等功能。' },
      ],
      cookieOption: [],
      rules: {
        'detailsSetting.detailTitle': [
          { required: true, message: 'Banner标题不能为空', trigger: 'change' },
        ],
        'detailsSetting.detailDesc': [
          { required: true, message: '不能为空', trigger: 'change' },
        ],
        'detailsSetting.privacyTextColor': [
          { required: true, message: '不能为空', trigger: 'change' },
        ],
        'detailsSetting.setText': [
          { required: true, message: '不能为空', trigger: 'change' },
        ],
        'detailsSetting.openColor': [
          { required: true, message: '不能为空', trigger: 'change' },
        ],
        'detailsSetting.saveBtnText': [
          { required: true, message: '不能为空', trigger: 'change' },
        ],
        'detailsSetting.saveBtnTextColor': [
          { required: true, message: '不能为空', trigger: 'change' },
        ],
        'detailsSetting.saveBtnBgColor': [
          { required: true, message: '不能为空', trigger: 'change' },
        ],
        'detailsSetting.detailAgreeAllBtnText': [
          { required: true, message: '不能为空', trigger: 'change' },
        ],
        'detailsSetting.detailAgreeAllBtnColor': [
          { required: true, message: '不能为空', trigger: 'change' },
        ],
        'detailsSetting.detailAgreeAllBtnBgColor': [
          { required: true, message: '不能为空', trigger: 'change' },
        ],
        'detailsSetting.detailRefuseText': [
          { required: true, message: '不能为空', trigger: 'change' },
        ],
        'detailsSetting.detailRefuseColor': [
          { required: true, message: '不能为空', trigger: 'change' },
        ],
        'detailsSetting.detailRefuseBgColor': [
          { required: true, message: '不能为空', trigger: 'change' },
        ],
      },
    }
  },
  created() {
    this.getCookieOption()
  },
  methods: {
    colorChange(activeColor) {
      this.form.detailsSetting.openColor = activeColor
    },
    getCookieOption() {
      GetCookieOption().then(res => {
        this.cookieOption = res.data.data
      })
    },
    validate() {
      let flag = false
      this.$refs.form.validate(valid => {
        if (valid) {
          flag = true
        } else {
          flag = false
        }
      })
      return flag
    },
    logoFileClick() {
      document.getElementById('logoFile').click()
    },
    fileSelect() {
      const uploadDom = document.getElementById('logoFile')
      const formData = new FormData()
      formData.append('file', uploadDom.files[0])
      UploadFile(formData).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          success(resData.msg)
          this.logoPicture = resData.data.assessPath
          this.form.logoPicture = resData.data.filePath
        } else {
          error(resData.msg)
        }
      })
    },
  },
}
</script>

<style>
.preview-wrap {
  position: absolute;
  width: 40%;
  height: calc(100% - 20px);
  max-width: 400px;
  top: 20px;
  left: 0;
  background: #fff;
  border-radius: 0 0 0 5px;
}
.title {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
}
.preview-item {
  background: rgb(247, 248, 250);
  padding: 16px;
  max-height: 60%;
  overflow: auto;
}
.el-collapse-item__header {
  padding: 0 10px;
}
.el-collapse-item__content {
  padding: 0 10px 25px 10px;
}
.preview-btn {
  height: 40px;
  background: #f77234;
  text-align: center;
  line-height: 40px;
  color: #ffffff;
  border-radius: 5px;
}
.preview-btn-wrap .preview-btn {
  width: 50%;
}
.cookie-btns {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  padding: 10px;
}
</style>
