<template>
    <el-form
            ref="form"
            :model="form"
            label-width="120px"
    >
        <el-form-item
                label="主题名称"
                prop="title"
                :rules="[ { required: true, message: '主题名称不能为空', trigger: 'change' }]"
        >
            <el-input
                    v-input-filter
                    v-model="form.title"
                    maxlength="32"
            />
        </el-form-item>
        <el-form-item
                label="主题语言"
                prop="language"
                :rules="[ { required: true, message: '主题语言不能为空', trigger: 'change' }]"
        >
          <el-select v-model="form.language">
              <el-option label="简体中文" value="简体中文"></el-option>
              <el-option label="繁体中文" value="繁体中文"></el-option>
              <el-option label="英语" value="英语"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
                label="描述"
        >
            <el-input
                    v-model="form.describe"
                    v-input-filter
                    type="textarea"
                    placeholder="请输入描述"
            />
        </el-form-item>
        <el-form-item
                label="适用法规"
                prop="LawId"
                :rules="[{ required: true, message: '适用法规不能为空', trigger: 'change' }]"
        >
            <el-select v-model="form.LawId">
                <el-option
                        v-for="(item,index) in lawOptions"
                        :key="index"
                        :label="item.law"
                        :value="item.value"
                />
            </el-select>
        </el-form-item>
        <el-form-item
                label="主题色"
                prop="themeColor"
                :rules="[ { required: true, message: '不能为空', trigger: 'change' }]"
        >
            <el-color-picker @active-change="themeColorChange" v-model="form.themeColor"></el-color-picker>
        </el-form-item>
    </el-form>
</template>

<script>
  import { getAllLawList } from '@/api/complaince/complaince'

  export default {
    name: 'CookieSaveStepOne',
    props: {
      form: {
        type: Object
      }
    },
    created() {
      this.getAllLawList()
    },
    data() {
      return {
        lawOptions: []
      }
    },
    methods: {
      themeColorChange(activeColor){
        this.form.themeColor = activeColor
      },
      validate() {
        var flag = false
        this.$refs['form'].validate((valid) => {
          if (valid) {
            flag = true
          } else {
            flag = false
          }
        })
        return flag
      },
      getAllLawList() {
        this.lawOptions = []
        getAllLawList().then(res => {
          if(res.data.code === 0){
            debugger
            res.data.data.forEach(d => {
              d.laws.forEach(law => {
                this.lawOptions.push({law:law.law,value:law.id})
              })
            })
          }
        })
      },
    },

  }
</script>

<style scoped>

</style>
